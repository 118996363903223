import React, { useCallback, useEffect, useState } from 'react';

export const WithOverlay = ({ children }) => {
  const [overlay, setOverlay] = useState(false);
  const [error, setError] = useState(null);

  const fetchOverlay = useCallback(async () => {
    const pubKey = process.env.GATSBY_PUBLIC_KEY;
    const ucUrlBase = process.env.GATSBY_UPLOADCARE_URL_BASE;
    const sourceUrl = 'https://ucarecdn.com/d755fc26-70bb-4f77-ae91-6f4fdb055d3a/';

    try {
      const cdnData = await fetch(
        `${ucUrlBase}/from_url/?pub_key=${pubKey}&store=0&source_url=${sourceUrl}`
      ).then((response) => response.json());

      return await fetch(`${ucUrlBase}/from_url/status/?token=${cdnData.token}`).then((response) =>
        response.json()
      );
    } catch (e) {
      throw Error('Something went wrong, please try again.');
    }
  }, []);

  useEffect(() => {
    setError(null);

    fetchOverlay()
      .then(({ uuid }) => {
        setOverlay(uuid);
      })
      .catch(({ message }) => {
        setError(message);
      });
  }, [fetchOverlay]);

  if (error) {
    return <span>{error}</span>;
  }

  if (overlay) {
    return React.cloneElement(children, {
      transformation: `-/overlay/${overlay}/45px30p/100p,0p/`,
    });
  }

  return <span>Loading...</span>;
};

import frmBts from 'pretty-bytes';
import React, { useMemo } from 'react';
import { InfoBlock, InfoList, ItemLabel } from './styled_components';

export const Info = ({ format, width, height, size }) => {
  const bytes = useMemo(() => frmBts(size), [size]);
  return (
    <InfoBlock>
      <InfoList>
        <li>
          <ItemLabel>format:</ItemLabel> {format}
        </li>
        <li>
          <ItemLabel>dimensions:</ItemLabel> {width} x {height} px
        </li>
        <li>
          <ItemLabel>size:</ItemLabel> {bytes}
        </li>
      </InfoList>
    </InfoBlock>
  );
};

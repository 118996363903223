import React, { useEffect, useRef, useState } from 'react';
import { Text } from 'rebass/styled-components';
import { BigImage as StyledBigImage } from './styled_components';

const TIMEOUT = 3000;

export const BigImage = ({ src, srcSet, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const timeout = useRef();

  useEffect(() => {
    setLoaded(false);
    setShowLoader(false);
  }, [src, srcSet]);

  useEffect(() => {
    timeout.current = setTimeout(() => setShowLoader(true), TIMEOUT);

    return () => {
      if (timeout.current) {
        timeout.current = clearTimeout(timeout.current);
      }
    };
  }, [loaded]);

  return (
    <div>
      {showLoader && !loaded && (
        <div style={{ position: 'absolute' }}>
          <Text fontSize="20px" lineHeight="22px">
            Loading...
          </Text>
        </div>
      )}
      <StyledBigImage
        src={src}
        srcSet={srcSet}
        isLoading={!loaded}
        onLoad={() => setLoaded(true)}
        onLoadStart={() => setLoaded(false)}
        {...props}
      />
    </div>
  );
};

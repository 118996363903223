import styled, { css } from 'styled-components';

export const H2 = styled.h2`
  font-family: var(--font-headings);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.004px;

  margin: 0 0 25px;
  padding: 0;

  display: flex;
  align-items: center;

  color: #000000;
`;

export const H3 = styled.div`
  letter-spacing: 0.004px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-decoration: underline;
`;

export const Subsection = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InfoHeader = styled.h3`
  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004px;
  margin: 0 0 5px;

  color: #b5b5b5;
`;

export const InfoList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;

  color: #444444;

  letter-spacing: 0.004px;
`;

export const ItemLabel = styled.span`
  color: #bbbbbb;
`;

export const BigImage = styled.img`
  margin: 30px 0 0;
  display: block;
  max-width: 400px;
  transform: scale(1);
  opacity: 1;

  transition:
    opacity 0.1s ease-in-out,
    transform 0.3s ease-in-out;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.1;
      transform: scale(0.95);
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const Url = styled.div`
  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.004px;

  margin: 0 0 25px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  color: #444444;
`;

export const UrlPart = styled.span`
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 5px 12px;
  margin: 2px 8px;
  line-height: 24px;
  display: inline-block;

  cursor: default;

  background-color: ${({ active }) => (active ? '#000000' : '#222222')};

  &:first-child {
    margin-left: 0px;
  }
`;

export const OperationGroup = styled.div`
  width: 250px;
  padding: 20px;
`;

export const DynamicOperations = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 51%;
  justify-content: flex-start;
  border-radius: 15px;
  background-color: rgb(246, 246, 246);
  margin-top: 30px;
  min-width: 500px;
`;

export const OperationInputWrapper = styled.div`
  float: right;
  margin-top: -2px;
`;

export const OperationInput = styled.input`
  width: 80px;
  text-align: center;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  opacity: 0.5;
`;

import React, { useState } from 'react';
import { Flex } from 'rebass/styled-components';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar } from '../../components/Navbar';
import {
  HorizontalLine,
  ImageUpload,
  SingleTransformation,
  MultipleTransformations,
  UseCases,
  DynamicImage,
  SmartOperations,
} from '../../components/forImageTransformationsDemo';

export const frontmatter = {
  title: 'Image Processing Demo',
  description:
    "A demo of Uploadcare's CDN image transformations. Uploadcare supports dozens of on-the-fly image processing features through a simple to use URL interface. Easily build custom image processing workflows and automate most image manipulation and optimization tasks.",
  noindex: true,
};

const StepContainer = ({ active, ...props }) => (
  <Flex py={active ? 60 : 12} maxWidth={980} width="100%" flexDirection="column" {...props} />
);

const Imagetransformations = ({ location }) => {
  const [image, setImage] = useState();
  const [fileSelected, setFileSelected] = useState(false);

  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <Navbar type="custom" visual="light" />
      <main style={{ paddingBottom: '200px' }}>
        <Flex alignItems="center" flexDirection="column" px={12} pb={[5, 0]}>
          <StepContainer active>
            <ImageUpload
              image={image}
              fileSelected={fileSelected}
              onFileSelect={(file) => {
                setFileSelected(Boolean(file));
              }}
              onUpload={(file) => {
                setImage({
                  name: file.name,
                  src: file.cdnUrl,
                  uuid: file.uuid,
                  operations: [],
                  width: file.originalImageInfo.width,
                  height: file.originalImageInfo.height,
                  size: file.size,
                  format: file.originalImageInfo.format,
                });
              }}
              onReset={() => {
                setFileSelected(false);
                setImage(null);
              }}
            />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <SingleTransformation image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <MultipleTransformations image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <UseCases image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <DynamicImage image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <SmartOperations image={image} />
          </StepContainer>
          <HorizontalLine />
        </Flex>
      </main>
    </CommonLayout>
  );
};

export default Imagetransformations;

import React from 'react';
import { ImageUrl } from './shared';
import { BigImage } from './big_image';

export const ExampleImage = ({ image, transformation = '' }) => {
  return (
    <div>
      <ImageUrl src={image.src} transformation={transformation} />
      <BigImage src={image.src + transformation} />
    </div>
  );
};

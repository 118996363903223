import React from 'react';
import { Box } from 'rebass/styled-components';
import { ExampleImage } from './example_image';
import { H2, Paragraph } from './shared';
import { Url, UrlPart } from './styled_components';

export const MultipleTransformations = ({ image }) => {
  if (!image) {
    return <H2 color="#B5B5B5">Multiple Transformations</H2>;
  }
  return (
    <>
      <Box mb={20}>
        <H2>Multiple Transformations</H2>
      </Box>
      <Paragraph>
        Multiple transformations to an image can be applied by chaining operations together in the
        URL. This allows you to trivially build complex image-processing workflows.
      </Paragraph>
      <Url>
        <UrlPart>ucarecdn.com</UrlPart>/<UrlPart>:image_uuid</UrlPart>
        /-/
        <UrlPart>operation</UrlPart>/<UrlPart>parameter</UrlPart>
        /-/
        <UrlPart>operation</UrlPart>/<UrlPart>parameter</UrlPart>/
      </Url>
      <ExampleImage image={image} transformation="-/scale_crop/200x300/-/filter/carris/" />
    </>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';

export const H2 = (props) => (
  <Text
    as="h2"
    letterSpacing="0.004px"
    fontSize="28px"
    lineHeight="41px"
    fontWeight="400"
    {...props}
  />
);

export const H3 = (props) => (
  <Text
    as="h3"
    letterSpacing="0.004px"
    fontSize="20px"
    lineHeight="30px"
    fontWeight="400"
    {...props}
  />
);

export const Paragraph = (props) => (
  <Box mb={40} maxWidth={750}>
    <Text
      as="p"
      letterSpacing="0.004px"
      fontSize="20px"
      lineHeight="32px"
      color="#626262"
      fontWeight="400"
      {...props}
    />
  </Box>
);

export const Card = styled.div`
  background-color: #f9f9f9;
  border-radius: 15px;
  width: 100%;
`;

export const UploadedFile = styled.div`
  background-color: #eaeaea;
  border-radius: 4px;
  display: inline-flex;
  width: 100%;
`;

export const ImageUrl = ({ src, transformation }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        position: 'relative',
      }}
    >
      <Flex alignItems="center">
        <UploadedFile>
          <Flex py="2px" pl="15px" pr="11px" alignItems="center" maxWidth="calc(100vw - 102px)">
            <Text
              fontFamily="var(--font-mono)"
              fontSize="16px"
              lineHeight="29px"
              letterSpacing="0.004px"
              color="#444444"
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
              `}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{src}</span>
              <span style={{ whiteSpace: 'wrap' }}>{transformation}</span>
            </Text>
          </Flex>
        </UploadedFile>
      </Flex>
    </div>
  );
};

import { Widget } from '@uploadcare/react-widget/en-min';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';
import { isBrowser } from '@uc-common/utils';
import { Card, H2, Paragraph } from './shared';
import { CDN_BASE } from '../../constants/api';
import { ExampleImage } from './example_image';
import { Url, UrlPart } from './styled_components';

const DEFAULT_COLOR = '#FFFFFF';
const DEFAULT_BG = '#0E54FF';
const DEFAULT_TEXT = 'Upload image';
const texts = {
  buttons: { choose: { images: { one: DEFAULT_TEXT } } },
};

const Global = styled.div`
  & .uploadcare--widget__button {
    color: ${DEFAULT_COLOR};
    color: var(--widget-color);

    background-color: ${DEFAULT_BG};
    background-color: var(--widget-bg-color);
  }
`;

const ImagePreset = styled.img`
  border-radius: 4px;
  cursor: grab;
`;

const PresetButton = styled.button`
  background: none;
  border: none;
  outline: none;

  &:focus-visible {
    outline: 2px solid rgba(21, 124, 252, 0.5);
    outline-offset: 1px;
  }
`;

const Label = (props) => (
  <Text fontSize={16} lineHeight="21px" letterSpacing="0.004px" color="#626262" {...props} />
);

const useTouchSupport = () => {
  return useMemo(() => isBrowser() && 'ontouchstart' in document.documentElement, []);
};

export const ImageUpload = ({ image, fileSelected, onUpload, onFileSelect }) => {
  const touchSupport = useTouchSupport();
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const button = ref.current.querySelector(
        '.uploadcare--widget__button.uploadcare--widget__button_type_open'
      );

      if (button) {
        button.innerText = DEFAULT_TEXT;
      }
    }
  });

  const onPresetSelect = () => {
    onUpload({
      name: 'lizard.jpg',
      cdnUrl: `${CDN_BASE}/eac5a08e-a60b-4407-a777-98f6d4e8d4a6/`,
      uuid: 'eac5a08e-a60b-4407-a777-98f6d4e8d4a6',
      size: 2302340,
      originalImageInfo: {
        format: 'JPEG',
        height: 3024,
        width: 3024,
      },
    });
  };

  return (
    <Flex flexDirection="column">
      <Box mb={20}>
        <H2>Image Transformations</H2>
      </Box>
      <Paragraph>
        With Uploadcare, you can easily build custom image transformation workflows and automate
        most image manipulation and optimization tasks. Our image CDN eliminates the need to store,
        manage, and serve images or their generated variants. All of our processing is done
        on-the-fly and allows you to easily transform images without building or maintaining your
        own processing systems. This saves developer time by empowering them to work quickly and
        efficiently with images.
      </Paragraph>
      <Box mb={20}>
        <H2>Upload an Image</H2>
      </Box>
      {image ? (
        <>
          <Paragraph>
            When a file is uploaded to our servers, a UUID is generated. This UUID is combined with
            the domain of our CDN to create a unique URL referencing the file.
          </Paragraph>
          <Url>
            <UrlPart>ucarecdn.com</UrlPart>/<UrlPart>:image_uuid</UrlPart>/
          </Url>
          <ExampleImage image={image} />
        </>
      ) : (
        <Card fullWidth>
          <Flex px={30} flexDirection={['column', 'row']}>
            <Flex
              py={30}
              flex="1"
              justifyContent="center"
              alignItems="center"
              flexDirection={['column', 'row']}
            >
              <Global
                ref={ref}
                style={{
                  '--widget-color': DEFAULT_COLOR,
                  '--widget-bg-color': DEFAULT_BG,
                }}
              >
                <Widget
                  imagesOnly
                  doNotStore
                  publicKey={process.env.GATSBY_PUBLIC_KEY}
                  locale="en"
                  localeTranslations={texts}
                  onChange={onUpload}
                  onFileSelect={onFileSelect}
                />
              </Global>

              {!fileSelected && (
                <>
                  <Box px={20} py={[3, 0]}>
                    <Label>or {touchSupport ? 'pick' : 'drag'} this one</Label>
                  </Box>
                  <PresetButton onClick={onPresetSelect}>
                    <ImagePreset
                      onClick={onPresetSelect}
                      onDragStart={(e) => {
                        e.dataTransfer.setData(
                          'text/uri-list',
                          `${CDN_BASE}/d7d83bcb-886a-479b-a724-8a1c52cdbb3e/`
                        );
                      }}
                      width="48"
                      height="48"
                      src={`${CDN_BASE}/d7d83bcb-886a-479b-a724-8a1c52cdbb3e/-/resize/96x96/-/format/auto/-/quality/lightest/`}
                    />
                  </PresetButton>
                </>
              )}
            </Flex>
          </Flex>
        </Card>
      )}
    </Flex>
  );
};

import filters from './filters';

export const cropOperation = {
  value: 'crop',
  inputs: [
    { type: 'text', label: 'Dimensions', defaultValue: '300x300' },
    { type: 'text', label: 'Origin', defaultValue: '50,50' },
  ],
};

export const operationGroups = [
  {
    name: 'Color Adjustments',
    operations: [
      { value: 'brightness', inputs: [{ type: 'text', defaultValue: '50' }] },
      { value: 'exposure', inputs: [{ type: 'text', defaultValue: '100' }] },
      { value: 'gamma', inputs: [{ type: 'text', defaultValue: '300' }] },
      { value: 'contrast', inputs: [{ type: 'text', defaultValue: '100' }] },
      { value: 'saturation', inputs: [{ type: 'text', defaultValue: '100' }] },
      { value: 'vibrance', inputs: [{ type: 'text', defaultValue: '100' }] },
      { value: 'warmth', inputs: [{ type: 'text', defaultValue: '30' }] },
    ],
  },
  {
    name: 'Color Changes',
    operations: [
      { value: 'grayscale' },
      { value: 'invert' },
      {
        value: 'filter',
        inputs: [
          {
            type: 'select',
            defaultValue: 'carris',
            options: filters,
          },
        ],
      },
    ],
  },
  {
    name: 'Focus',
    operations: [
      { value: 'blur', inputs: [{ type: 'text', defaultValue: '20' }] },
      {
        value: 'sharp',
        name: 'Sharpen',
        inputs: [{ type: 'text', defaultValue: '5' }],
      },
      { value: 'enhance', inputs: [{ type: 'text', defaultValue: '30' }] },
    ],
  },
  {
    name: 'Rotation',
    operations: [
      {
        value: 'rotate',
        inputs: [{ type: 'select', defaultValue: '90', options: ['90', '180', '270'] }],
      },
      { value: 'flip' },
      { value: 'mirror' },
    ],
  },
  {
    name: 'Compression & Formatting',
    operations: [
      {
        value: 'format',
        inputs: [
          {
            type: 'select',
            defaultValue: 'webp',
            options: ['jpeg', 'png', 'webp', 'auto'],
          },
        ],
      },
      {
        value: 'quality',
        inputs: [
          {
            type: 'select',
            defaultValue: 'lightest',
            options: ['normal', 'better', 'best', 'lighter', 'lightest', 'smart'],
          },
        ],
      },
      {
        value: 'progressive',
        inputs: [{ type: 'select', defaultValue: 'yes', options: ['yes', 'no'] }],
      },
    ],
  },
];

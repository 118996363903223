import React from 'react';
import { Box } from 'rebass/styled-components';
import { ExampleImage } from './example_image';
import { H2, Paragraph } from './shared';
import { Url, UrlPart } from './styled_components';

export const SingleTransformation = ({ image }) => {
  if (!image) {
    return <H2 color="#B5B5B5">Applying Transformations</H2>;
  }
  return (
    <>
      <Box mb={20}>
        <H2>Applying Transformations</H2>
      </Box>
      <Paragraph>
        To transform an image with Uploadcare, you simply append the name of the desired operation
        and any need parameters to the URL of the image. The new image variant is generated
        instantly and can be referenced through this modified URL.
      </Paragraph>
      <Url>
        <UrlPart>ucarecdn.com</UrlPart>/<UrlPart>:image_uuid</UrlPart>
        /-/
        <UrlPart>operation</UrlPart>/<UrlPart>parameter</UrlPart>/
      </Url>
      <ExampleImage image={image} transformation="-/scale_crop/200x300/" />
    </>
  );
};

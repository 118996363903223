import React, { useEffect, useState, useCallback } from 'react';

export const WithImageInfo = ({ children, imageUrl }) => {
  const [info, setInfo] = useState();
  const [error, setError] = useState(null);

  const fetchImageInfo = useCallback(async () => {
    try {
      const response = await fetch(imageUrl);
      const { headers } = response;

      const format = headers.get('Content-Type').replace('image/', '').toUpperCase();

      return {
        format,
        width: Number(headers.get('X-Image-Width')),
        height: Number(headers.get('X-Image-Height')),
        size: Number(headers.get('Content-Length')),
      };
    } catch (e) {
      throw Error('Something went wrong, please try again.');
    }
  }, [imageUrl]);

  useEffect(() => {
    setError(null);

    fetchImageInfo()
      .then(setInfo)
      .catch(({ message }) => {
        setError(message);
      });
  }, [fetchImageInfo]);

  if (error) {
    return <span>{error}</span>;
  }

  if (info) {
    return React.cloneElement(children, info);
  }

  return <span>Loading...</span>;
};

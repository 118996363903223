import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { H2, Paragraph, ImageUrl } from './shared';
import { BigImage } from './big_image';
import { H3, Subsection } from './styled_components';

export const SmartOperations = ({ image }) => {
  if (!image) {
    return <H2 color="#B5B5B5">Smart Operations</H2>;
  }
  return (
    <>
      <Box mb={20}>
        <H2>Smart Operations</H2>
      </Box>
      <Paragraph>
        In addition to basic image transformations, Uploadcare offers various &ldquo;smart&rdquo;
        operations that make use of our Image Intelligence system. These operations enable the
        processing of large amounts of images in a content-aware manner.
      </Paragraph>
      <Subsection>
        <H3>Smart Blur</H3>
        <Paragraph>
          The Smart Blur transformation blurs images based on facial detection. Rather than having
          to manually identify the location of faces in images to individually blur them out, this
          operation allows facial blurring to be done automatically in a single transformation.
        </Paragraph>
        <ImageUrl
          src="https://ucarecdn.com/0ee08574-6f34-4740-841e-8f3ab8993d68/"
          transformation="-/preview/-/blur_region/faces/150/"
        />
        <Flex>
          <div style={{ flexBasis: '50%' }}>
            <BigImage
              src="https://ucarecdn.com/0ee08574-6f34-4740-841e-8f3ab8993d68/"
              style={{ width: '400px' }}
            />
          </div>
          <div style={{ flexBasis: '50%' }}>
            <BigImage
              src="https://ucarecdn.com/0ee08574-6f34-4740-841e-8f3ab8993d68/-/preview/-/blur_region/faces/150/"
              style={{ width: '400px' }}
            />
          </div>
        </Flex>
      </Subsection>
      <Subsection>
        <H3>Smart Crop</H3>
        <Paragraph>
          Smart Cropping allows you to crop images based on objects and faces detected in the image.
          Simply specify the size of the desired output and the image will be cropped around the
          most prominent object in the image. This operation allows context aware cropping to be
          done automatically across large numbers of images without having to individually specify
          relevant portions of the images.
        </Paragraph>
        <ImageUrl
          src="https://ucarecdn.com/36cda64e-7f8c-4e54-89f0-6a7b1d90a8dd/"
          transformation="-/scale_crop/300x300/smart/"
        />
        <Flex>
          <div style={{ flexBasis: '50%' }}>
            <BigImage
              src="https://ucarecdn.com/36cda64e-7f8c-4e54-89f0-6a7b1d90a8dd/"
              style={{ width: '400px' }}
            />
          </div>
          <div style={{ flexBasis: '50%' }}>
            <BigImage src="https://ucarecdn.com/36cda64e-7f8c-4e54-89f0-6a7b1d90a8dd/-/scale_crop/300x300/smart/" />
          </div>
        </Flex>
      </Subsection>
    </>
  );
};

import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ExampleImage } from './example_image';
import { Info } from './info';
import { BigImage } from './big_image';
import { H2, Paragraph, ImageUrl } from './shared';
import { H3, Subsection } from './styled_components';
import { WithImageInfo } from './WithImageInfo';
import { WithOverlay } from './WithOverlay';

export const UseCases = ({ image }) => {
  const optimizationTransformation = '-/resize/300/-/format/auto/-/quality/normal/';
  if (!image) {
    return <H2 color="#B5B5B5">Common Use Cases</H2>;
  }
  return (
    <>
      <Box mb={20}>
        <H2>Common Use Cases</H2>
      </Box>
      <Subsection>
        <H3>Standardizing User-Generated Content - Avatars</H3>
        <Paragraph>
          Images uploaded by users are generally oversized with unpredictable aspect ratios.
          Uploadcare&apos;s image CDN makes standardizing user-generated images trivial. Here we see
          an example of creating a properly-sized user avatar from the original image.
        </Paragraph>
        <ExampleImage image={image} transformation="-/resize/100/" />
      </Subsection>
      <Subsection>
        <H3>Applying Overlays</H3>
        <Paragraph>
          Many scenarios for using images require that multiple images be combined. Logos, offers
          and text may need to be overlaid on top an image. Uploadcare offers powerful functionality
          to enable this use case allowing for multiple overlays and variable placement and sizing.
        </Paragraph>

        <WithOverlay>
          <ExampleImage image={image} />
        </WithOverlay>
      </Subsection>
      <Subsection>
        <H3>Optimizing Images</H3>
        <Paragraph>
          Optimizing images for the web is a complex and frustrating task. Many variants of an image
          need to be generated to serve different contexts and multiple transformations need to be
          performed for each one. Uploadcare&apos;s sizing, formatting and compression
          transformations drastically simplify this process.
        </Paragraph>
        <ImageUrl src={image.src} transformation={optimizationTransformation} />
        <Flex style={{ marginTop: '30px' }}>
          <div style={{ flexBasis: '50%' }}>
            <H3>Original Image</H3>
            <BigImage src={image.src} style={{ width: '300px' }} />
            <Info {...image} />
          </div>
          <div style={{ flexBasis: '50%' }}>
            <H3>Optimized Image</H3>
            <BigImage src={image.src + optimizationTransformation} style={{ width: '300px' }} />

            <WithImageInfo imageUrl={image.src + optimizationTransformation}>
              <Info />
            </WithImageInfo>
          </div>
        </Flex>
      </Subsection>
    </>
  );
};

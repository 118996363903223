import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { H2, ImageUrl } from './shared';
import {
  H3,
  OperationGroup,
  DynamicOperations,
  OperationInput,
  OperationInputWrapper,
} from './styled_components';
import { BigImage } from './big_image';
import { cropOperation, operationGroups } from './operations';

const SizeInput = ({
  value,
  name,
  inputType,
  defaultInputValue,
  updateTransformation,
  checked = false,
}) => {
  const [inputParameter, setInputParameter] = useState(defaultInputValue);
  const inputField = useRef();

  const onRadioSelect = (e) => {
    updateTransformation(e.target.value, inputParameter);
    if (inputType) {
      inputField.current.focus();
    }
  };

  const onInputChange = (e) => {
    setInputParameter(e.target.value);
    updateTransformation(value, e.target.value);
  };

  return (
    <div>
      <input
        onChange={onRadioSelect}
        type="radio"
        value={value}
        name="sizeOperation"
        id={value}
        checked={checked}
        style={{ margin: '3px 6px 0px 5px' }}
      />
      <label htmlFor={value}>
        {name || value.charAt(0).toUpperCase() + value.slice(1)}
        {inputType && ':'}
      </label>
      {inputType && (
        <OperationInputWrapper>
          <OperationInput
            onChange={onInputChange}
            type={inputType}
            defaultValue={defaultInputValue}
            name="parameter"
            ref={inputField}
          />
        </OperationInputWrapper>
      )}
    </div>
  );
};

const TransformInput = ({
  value,
  name,
  inputs = [],
  addTransformation,
  removeTransformation,
  updateTransformation,
}) => {
  const [checked, setChecked] = useState(false);
  const [inputParameters, setInputParameters] = useState(inputs.map((input) => input.defaultValue));
  const defaultInputField = useRef();

  const onCheck = (e) => {
    setChecked(!checked);
    if (e.target.checked) {
      addTransformation(value, inputParameters);
    } else {
      removeTransformation(value);
    }

    if (inputs.length) {
      if (e.target.checked) {
        defaultInputField.current.focus();
      } else {
        defaultInputField.current.blur();
      }
    }
  };

  const onInputChangeGenerator = (index) => {
    return (e) => {
      const newInputs = inputParameters;
      newInputs[index] = e.target.value;
      setInputParameters(newInputs);
      updateTransformation(value, newInputs);
    };
  };

  return (
    <div>
      <input
        onChange={onCheck}
        type="checkbox"
        value={value}
        name="operation"
        id={value}
        checked={checked}
        style={{ margin: '3px 6px 0px 5px' }}
      />
      <label htmlFor={value}>
        {name || value.charAt(0).toUpperCase() + value.slice(1)}
        {inputs.length > 0 && ':'}
      </label>
      {inputs.map((input, index) => (
        <OperationInputWrapper key={index}>
          {input.type === 'text' ? (
            <OperationInput
              onChange={onInputChangeGenerator(index)}
              type="text"
              defaultValue={input.defaultValue}
              name="parameter"
              ref={index === 0 ? defaultInputField : null}
            />
          ) : (
            <select
              onChange={onInputChangeGenerator(index)}
              value={inputParameters[0]}
              ref={index === 0 ? defaultInputField : null}
            >
              {input.options.map((option, index) => (
                <option value={option} key={index}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </option>
              ))}
            </select>
          )}
        </OperationInputWrapper>
      ))}
    </div>
  );
};

export const DynamicImage = ({ image }) => {
  const [sizeOperation, setSizeOperation] = useState('preview');
  const [sizeParameter, setSizeParameter] = useState();
  const [transformations, setTransformations] = useState([]);
  const [urlPostfix, setUrlPostfix] = useState('');

  useEffect(() => {
    let newUrlPostfix = `-/${sizeOperation}/`;
    if (sizeParameter) {
      newUrlPostfix += `${sizeParameter}/`;
    }
    newUrlPostfix += transformations
      .map((transformation) => {
        return `-/${transformation.join('/')}/`;
      })
      .join('');
    setUrlPostfix(newUrlPostfix);
  }, [sizeOperation, sizeParameter, transformations]);

  const updateSizeTransformation = (operation, param) => {
    setSizeOperation(operation);
    setSizeParameter(param);
  };

  const addTransformation = (operation, params) => {
    setTransformations([...transformations, [operation, ...params]]);
  };
  const removeTransformation = (operation) => {
    setTransformations(
      transformations.filter((transformation) => {
        return transformation[0] !== operation;
      })
    );
  };

  const updateTransformation = (operation, params) => {
    setTransformations(
      transformations.map((transformation) => {
        if (transformation[0] === operation) {
          return [operation, ...params];
        }
        return transformation;
      })
    );
  };

  if (!image) {
    return <H2 color="#B5B5B5">Basic Operations</H2>;
  }
  return (
    <>
      <Box mb={20}>
        <H2>Basic Operations</H2>
      </Box>
      <ImageUrl src={image.src} transformation={urlPostfix} />
      <Flex justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
        <DynamicOperations>
          <OperationGroup>
            <H3>Sizing:</H3>
            <SizeInput
              value="preview"
              updateTransformation={updateSizeTransformation}
              checked={sizeOperation === 'preview'}
            />
            <SizeInput
              value="resize"
              inputType="text"
              defaultInputValue="100"
              updateTransformation={updateSizeTransformation}
              checked={sizeOperation === 'resize'}
            />
            <SizeInput
              value="scale_crop"
              name="Scale Crop"
              inputType="text"
              defaultInputValue="200x300"
              updateTransformation={updateSizeTransformation}
              checked={sizeOperation === 'scale_crop'}
            />
            <TransformInput
              {...cropOperation}
              addTransformation={addTransformation}
              removeTransformation={removeTransformation}
              updateTransformation={updateTransformation}
            />
          </OperationGroup>
          {operationGroups.map((group) => (
            <OperationGroup key={group.name}>
              <H3>{group.name}:</H3>
              {group.operations.map((operation, index) => (
                <TransformInput
                  {...operation}
                  key={index}
                  addTransformation={addTransformation}
                  removeTransformation={removeTransformation}
                  updateTransformation={updateTransformation}
                />
              ))}
            </OperationGroup>
          ))}
        </DynamicOperations>
        <BigImage src={image.src + urlPostfix} />
      </Flex>
    </>
  );
};
